import './init_glob.js';
import './delaweb.js';

window.incomingParams = {};
window.incomingParams.changes = [];
window.incomingParams.defaultParams = {};

window.addEventListener('message', (event) => { 
    if (Array.isArray(event.data)) incomingParams.defaultParams = event;
    else incomingParams.changes.push(event);
});