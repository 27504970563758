import * as THREE from "three";
import { loader } from "./loader.js";
import { onResize } from "./resize.js";
import * as Camera from "./Camera.js";
import { materials } from "./materials.js";
import GUI from "lil-gui";

const container3D = document.querySelector(".d3d-container");

const renderer = new THREE.WebGLRenderer({
	antialias: true,
	toneMapping: THREE.ACESFilmicToneMapping,
	outputColorSpace: THREE.SRGBColorSpace,
	alpha: true,
});
const canvas = renderer.domElement;
container3D.appendChild(canvas);
renderer.setSize(container3D.offsetWidth, container3D.offsetHeight);

window.container3D = container3D;
window.container3D.canvas = canvas;

const scene = new THREE.Scene();
let activeCamera;
scene.setActiveCamera = (camera) => {
	activeCamera = camera;
	onResize(renderer, activeCamera, container3D);
};

const sceneLoadPromise = new Promise(function (resolve, reject) {
	loader.loadGLTF("./3d/delaweb.glb", async (gltf) => {
		scene.add(gltf.scene);
		scene.animations = gltf.animations;
		resolve();

		//#region Camera
		const importedCamera = scene.getObjectByName("camera");
		const importedCameraTarget = scene.getObjectByName("camera_target");
		activeCamera = new Camera.Perspective(
			container3D.canvas,
			importedCamera.fov,
			importedCamera.aspect
		);
		activeCamera.name = importedCamera.name;
		activeCamera.controls.setLookAt(
			importedCamera.position.x,
			importedCamera.position.y,
			importedCamera.position.z,
			importedCameraTarget.position.x,
			importedCameraTarget.position.y,
			importedCameraTarget.position.z
		);
		activeCamera.controls.minDistance = .3;
		activeCamera.controls.maxDistance = 1;
		//#endregion

		//#region Materials
		materials.list.forEach((mat) => {
			if (mat.aoMap) mat.aoMap.channel = 1;
		});
		materials.list.find((mat) => mat.name === "chromed_metal").aoMapIntensity = 1;
		//#endregion

		//#region Logic
		const onTerminalTypeChange = (value) => {
			const material = materials.list.find((mat) => mat.name === "colored_plastic");
			if (value === 'JXB-2.5') material.color = new THREE.Color(0x545454);
			if (value === 'JXB-2.5 PE') material.color = new THREE.Color(0x134014);		
		};

		const onTerminalAmountChange = (value) => {
			const terminal = scene.getObjectByName('box_terminals').children;
			terminal.forEach(mesh => mesh.morphTargetInfluences[0] = value - 1);
		};

		const inputObjects = scene.getObjectByName('inputs').children;
		const onInputChange = (data) => {
			let {inputName, entityType, value} = data;
			inputName = inputName.toUpperCase();
			const remapedNames = {
				"DSA1MBNS": inputName + "_input_armored_20",
				"DSA2MBNS": inputName +"_input_armored_25",
				"LT-B-TS1MBN": inputName + "_input_plug_20", 
				"LT-B-TS2MBN": inputName + "_input_plug_25",
			}
			inputObjects.forEach(obj => {if (obj.name.includes(inputName + '_')) obj.visible = false});
			inputObjects.find(obj => obj.name === remapedNames[value]).visible = true;	
		}

		const applyChanges = (data) => {
			if (data.entityType === 'input') onInputChange(data);
			if (data.entityType === 'cleat' && data.changingType === 'amount') onTerminalAmountChange(data.value);
			if (data.entityType === 'cleat' && data.changingType === 'type') onTerminalTypeChange(data.value);
		}

		incomingParams.defaultParams.data.forEach(data => applyChanges(data));
		incomingParams.changes.forEach(event => applyChanges(event.data))
		
		window.addEventListener('message', (event) => {
			applyChanges(event.data)	
		});
		//#endregion
		
		onResize(renderer, activeCamera, container3D);
		window.addEventListener("resize", () => onResize(renderer, activeCamera, container3D));

		animate();
	}, true);
});

loader.loadTextures("./textures", ["overcast_soil_puresky_1k.hdr"], async (textures) => {
	scene.environment = textures.find((texture) => texture.name === "overcast_soil_puresky_1k.hdr");
	scene.environmentIntensity = 1;
	scene.background = null;
});

const clock = new THREE.Clock();
function animate() {
	const deltaTime = clock.getDelta();

	requestAnimationFrame(animate);

	activeCamera.update(deltaTime);

	renderer.render(scene, activeCamera);
}
