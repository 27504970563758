import * as THREE from "three";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";

const materialList = [];

export const materials = {
	list: materialList,
	setMaterial: setMaterial,
};

export function setMaterial(object) {
	let existingMaterial;
	if (materialList.length > 0) {
		existingMaterial = materialList.find((mat) => mat.name === object.material.name);
	}

	if (existingMaterial) {
		object.material = existingMaterial.isUnique ? existingMaterial.clone() : existingMaterial;
		return;
	}

	if (object.material.type === "MeshBasicMaterial") {
		let oldMaterial = object.material.clone();
		object.material = customBasicMaterial();
		object.material.copy(oldMaterial);
	}

	materialList.push(object.material);
}

function customBasicMaterial() {
	const material = new CustomShaderMaterial({
		baseMaterial: THREE.MeshBasicMaterial,
		vertexShader: ``,
		fragmentShader: `
		uniform float uEmissiveIntensity;
        void main(){
			csm_DiffuseColor *= vec4(vec3(uEmissiveIntensity), 1);
        }
        `,
	});
	material.emissiveIntensity = 1;
	material.uniforms = { uEmissiveIntensity: new THREE.Uniform(material.emissiveIntensity) };

	return material;
}

